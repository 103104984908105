import { instance } from "../axios/axiosInstance";

import { store } from "../redux/store";
import { ApiKind } from "../types/api";
import { BlogActionKind, blogType } from "../types/blog";
import { UserActionKind } from "../types/user";
interface IFormInput {
  title: string;
  image: File;
  body: string;
}
export const getBlogs = function () {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.GETBLOGS,
      method: "GET",
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        res.data?.sort(function (a: blogType, b: blogType) {
          const bDate: any = new Date(b.created_at);
          const aDate: any = new Date(a.created_at);

          return bDate - aDate;
        });
        store.dispatch({ type: BlogActionKind.GETALL, payload: res.data });
        resolve(res.data.message);
      })
      .catch((error) => {
        store.dispatch({ type: UserActionKind.LOGOUT });
        store.dispatch({ type: BlogActionKind.LOGOUT });
        reject(error);
      });
  });
};
export const updateUser = function (data: any, id: number) {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.UPDATEUSER + id,
      method: "POST",
      data: data,
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        store.dispatch({
          type: UserActionKind.UPDATEUSER,
          payload: res.data.data,
        });
        resolve(res.data.message);
      })
      .catch((error) => {
        store.dispatch({ type: UserActionKind.LOGOUT });
        store.dispatch({ type: BlogActionKind.LOGOUT });
        reject(error);
      });
  });
};
export const createUser = function (data: any) {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.CREATEUSER,
      method: "POST",
      data: data,
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        store.dispatch({
          type: UserActionKind.CREATENEWUSER,
          payload: res.data.data,
        });
        resolve(res.data.message);
      })
      .catch((error) => {
        store.dispatch({ type: UserActionKind.LOGOUT });
        store.dispatch({ type: BlogActionKind.LOGOUT });
        reject(error);
      });
  });
};
export const getUsers = function () {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.GETUSERS,
      method: "GET",
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        store.dispatch({
          type: UserActionKind.GETALLUSERS,
          payload: res.data.users,
        });
        resolve(res.data.message);
      })
      .catch((error) => {
        store.dispatch({ type: UserActionKind.LOGOUT });
        store.dispatch({ type: BlogActionKind.LOGOUT });
        reject(error);
      });
  });
};
export const deleteBlog = function (id: number) {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.DELETEBLOG + id,
      method: "DELETE",
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        store.dispatch({ type: BlogActionKind.DELETE, payload: id });
        resolve(res.data.message);
      })
      .catch((error) => {
        store.dispatch({ type: UserActionKind.LOGOUT });
        store.dispatch({ type: BlogActionKind.LOGOUT });
        reject(error);
      });
  });
};
export const createBlog = function (data: any) {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.CREATEBLOG,
      method: "POST",
      data: data,
      headers: {
        Authorization: "Bearer " + state.user.token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        store.dispatch({ type: BlogActionKind.ADD, payload: res.data.data });
        resolve(res.data.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateBlog = function (data: any, id: number) {
  console.log(data);
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.UPDATEBLOG + id,
      method: "POST",
      data: data,
      headers: {
        Authorization: "Bearer " + state.user.token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        store.dispatch({ type: BlogActionKind.UPDATE, payload: res.data.data });
        resolve(res.data.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
