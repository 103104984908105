import React from "react";
import { formatDate } from "../../helpers/date";
import { ApiKind } from "../../types/api";
import { blogType } from "../../types/blog";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import Modal from "../modal/Modal";
import { deleteBlog } from "../../helpers/getters";
import Snackbar, { SnackEnum } from "../Snackbar/Snackbar";
type Props = {
  blog: blogType;
};

const Blog = ({ blog }: Props) => {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<SnackEnum>(SnackEnum.success);
  const snackRef = React.useRef<any>(null);
  const modalRef = React.useRef<any>(null);
  const date = new Date(blog.created_at);
  const cleanHTML = DOMPurify.sanitize(blog.body, {
    USE_PROFILES: { html: true },
  });
  const deleteBlogFunction = () => {
    deleteBlog(blog.id)
      .then((response) => {
        setMessage(response);
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        if (snackRef.current) {
          snackRef.current.show();
        }
      });
  };
  return (
    <>
      <Modal
        message="Are you sure you want to delete this article?"
        ref={modalRef}
        action={deleteBlogFunction}
        id={blog.id}
      />
      <Snackbar message={message} type={status} ref={snackRef} />
      <div className="flex flex-col  border-2 border-gray-200 p-2 rounded-md ">
        <div
          style={{
            backgroundSize: "cover",
          }}
          className=" h-[330px] w-full hover:bg-gradient-to-r from-indigo-500 transition-all duration-500 cursor-pointer"
        >
          <img
            src={ApiKind.IMAGE + `${blog?.image?.path}`}
            alt={blog?.image?.path}
            className="hover:opacity-40 h-full w-full transition-all duration-500"
            onClick={() =>
              navigate(`/article/${blog.id}`, { state: { blog: blog } })
            }
          />
        </div>
        <div className="flex flex-col justify-end items-start mx-3 gap-8 mt-4">
          <div className="flex justify-items-start text-start">
            <h4 className="text-xl">{blog.title}</h4>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm">
              {formatDate(date)} | {blog.category ? blog.category.title : ""}
            </div>
            <div>
              <div className="">
                {cleanHTML?.length > 200 ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cleanHTML?.slice(0, 160),
                    }}
                    className="my-4"
                  ></p>
                ) : (
                  <p
                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                    className="my-4"
                  ></p>
                )}
                {cleanHTML?.length > 200 && (
                  <button
                    className="underline"
                    onClick={() =>
                      navigate(`/article/${blog.id}`, { state: { blog: blog } })
                    }
                  >
                    Read More
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="justify-end items-end flex w-full">
            <button
              className="bg-red-500 text-gray-50 px-2 py-1 rounded-md"
              onClick={() => modalRef.current.show()}
            >
              DELETE
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
