import React from "react";
import { GiCancel } from "react-icons/gi";
import { motion } from "framer-motion";
export enum SnackEnum {
  success = "success",
  error = "error",
  warning = "warning",
}
type SnackbarProps = {
  message: string;
  type: SnackEnum;
};
const variants = {
  open: { opacity: 1, y: 10 },
  closed: { opacity: 0, y: -600 },
};
const Snackbar = React.forwardRef((props: SnackbarProps, ref) => {
  const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
  React.useImperativeHandle(ref, () => ({
    show(): any {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 5000);
    },
  }));
  return (
    <div className="flex fixed justify-center w-screen z-20">
      <motion.div
        animate={showSnackbar ? "open" : "closed"}
        variants={variants}
        className="h-full"
        initial={false}
      >
        <div
          className={`${
            props.type === "success" ? "bg-green-400" : "bg-red-500 "
          }  w-[350px] rounded-md px-2 py-2 flex flex-row`}
        >
          <h3 className="flex-1 text-gray-100">{props.message}</h3>
          <button
            className="text-gray-100"
            onClick={() => setShowSnackbar(false)}
          >
            <GiCancel />
          </button>
        </div>
      </motion.div>
    </div>
  );
});

export default Snackbar;
