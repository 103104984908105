interface creator {
  name: string;
  email: string;
}
interface category {
  id: string;
  title: string;
}
interface image {
  path: string;
}
export interface blogType {
  id: number;
  title: string;
  body: string;
  creator_id: number;
  creator: Array<creator>;
  category: category;
  image: image;
  created_at: string;
  update_at: string;
}
export enum BlogActionKind {
  GETALL = "GETALL",
  LOGOUT = "LOGOUT",
  CREATE = "CREATE",
  DELETE = "DELETE",
  ADD = "ADD",
  UPDATE = "UPDATE",
}
export interface BlogAction {
  type: BlogActionKind;
  payload?: any;
}
