import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { useQuill } from "react-quilljs";
import { useForm, SubmitHandler } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import Snackbar, { SnackEnum } from "../components/Snackbar/Snackbar";
import { ApiKind } from "../types/api";
import { CiUndo } from "react-icons/ci";
import { updateBlog } from "../helpers/getters";
import { useSelector } from "react-redux";
import { state } from "../types/initial";
import { blogType } from "../types/blog";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
type Props = {};
interface IFormInput {
  title: string;
  image: string;
  category: string;
}
const SingleArticle = (props: Props) => {
  const { category, users } = useSelector((state: state) => state);
  const { state } = useLocation();
  const blog: blogType = state.blog;
  const snackbarRef = React.useRef<any>(null);
  const [val, setVal] = React.useState<any>();
  const [newImage, setImage] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<SnackEnum>(SnackEnum.success);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  useForm({
    defaultValues: {
      title: blog.title,
    },
  });

  const { quill, quillRef } = useQuill();
  React.useEffect(() => {
    setValue("title", blog.title);
    setValue("category", blog?.category?.id);
  }, []);
  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(blog.body);
    }
  }, [quill]);
  const onSubmit: SubmitHandler<IFormInput> = (data, e) => {
    const formData = new FormData();
    if (data.image[0]) {
      formData.append("image", data.image[0]);
    }
    formData.append("title", data.title);
    formData.append("creator_id", JSON.stringify(val));
    formData.append("category_id", data.category);
    if (quill) {
      formData.append("body", quill.root.innerHTML);
    }
    updateBlog(formData, blog.id)
      .then((response) => {
        setMessage(response);
        if (snackbarRef.current) {
          snackbarRef.current.show();
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setStatus(SnackEnum.error);
        if (snackbarRef.current) {
          snackbarRef.current.show();
        }
      });
  };
  return (
    <>
      <Navbar />
      <Snackbar message={message} type={status} ref={snackbarRef} />
      <div className="mt-32 px-32 mb-32">
        <form
          className="space-y-4 border-2 px-8 py-12 rounded-lg"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="tracking-widest text-xl font-mono">Update Article</h3>
          <div className="flex flex-col">
            {errors.title && (
              <span role="alert" className=" text-red-600 text-center">
                {errors.title.message}
              </span>
            )}
            <input
              placeholder="Title"
              {...register("title", {
                required: "Title is Required",
              })}
              className={`p-2  outline-none rounded-md border-2 ${
                errors.title ? "border-2 border-red-500" : ""
              }`}
            />
          </div>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            placeholder="Συντάκτες"
            isMulti
            onChange={(choice) => setVal(choice)}
            options={users}
            defaultValue={blog.creator}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
          <div className="flex flex-col">
            {errors.category && (
              <span role="alert" className=" text-red-600 text-center">
                {errors.category.message}
              </span>
            )}

            <select
              {...register("category", { required: "Category is Required" })}
              className={`p-2  outline-none rounded-md border-2 ${
                errors.title ? "border-2 border-red-500" : ""
              }`}
              placeholder="Category"
              defaultValue={blog?.category?.id}
            >
              {category?.map((cat) => {
                return <option value={cat.id}>{cat.title}</option>;
              })}
            </select>
          </div>
          <div className="flex flex-col">
            {errors.image && (
              <span role="alert" className=" text-red-600 text-center">
                {errors.image.message}
              </span>
            )}
            <label>Main Image</label>
            {newImage ? (
              <div className="flex flex-row items-center space-x-12">
                <img src={newImage} alt="preview image" width={320} />
                <CiUndo
                  className="inline-block cursor-pointer"
                  size={32}
                  onClick={() => setImage("")}
                />
              </div>
            ) : (
              <img
                src={ApiKind.IMAGE + `${blog?.image?.path}`}
                alt={blog?.image?.path}
                width={320}
              />
            )}

            <input
              type="file"
              placeholder="Image"
              {...register("image", {
                onChange: (e) => {
                  onImageChange(e);
                },
              })}
              className={`p-2  outline-none rounded-md ${
                errors.title ? "border-2 border-red-500" : ""
              }`}
            />
          </div>
          <div ref={quillRef} />
          <button
            type="submit"
            className="bg-indigo-300 py-4 mx-auto block rounded-md text-gray-700 px-12 mt-4 hover:bg-gray-700 hover:text-indigo-300 transition-all duration-500"
          >
            Update
          </button>
        </form>
      </div>
    </>
  );
};

export default SingleArticle;
