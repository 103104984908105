import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Router, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import Create from "./pages/Create";
import { getBlogs, getUsers } from "./helpers/getters";
import { useSelector } from "react-redux";
import SingleArticle from "./pages/SingleArticle";
import { getCategories } from "./helpers/category";
import Categories from "./pages/Categories";
import Users from "./pages/Users";

function App() {
  const user = useSelector((state: any) => state.user);
  React.useEffect(() => {
    getBlogs()
      .then()
      .catch((error) => {
        console.log(error);
      });
    getCategories()
      .then()
      .catch((error) => {
        console.log(error);
      });

    getUsers()
      .then()
      .catch((error) => {
        console.log(error);
      });
  }, [user.auth]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route element={<Home />} path="/" />
            <Route element={<Create />} path="/create" />
            <Route element={<Categories />} path="/categories" />
            <Route element={<Users />} path="/users" />
            <Route path="/article/:id" element={<SingleArticle />} />
          </Route>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
