import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Snackbar, { SnackEnum } from "../components/Snackbar/Snackbar";
import { login } from "../helpers/login";

interface IFormInput {
  email: string;
  password: string;
}
type Props = {};
const Login = (props: Props) => {
  const snackbarRef = React.useRef<any>(null);
  const [message, setMessage] = React.useState<string>("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    login(data)
      .then((t) => {
        console.log(t);
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        if (snackbarRef.current) {
          snackbarRef.current.show();
        }
      });
  };

  return (
    <div className="overflow-hidden">
      <Snackbar message={message} type={SnackEnum.success} ref={snackbarRef} />
      <div className="flex  justify-center items-center align-middle h-screen bg-gray-300">
        <div className="bg-gray-100 px-20 py-20 rounded-md shadow-xl flex flex-col space-y-4">
          <div className="mb-12">
            <img src="/assets/logo.png" alt="logo" width={200} height={128} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex flex-col">
              {errors.email && (
                <span role="alert" className=" text-red-600 text-center">
                  {errors.email.message}
                </span>
              )}
              <input
                placeholder="Your Email"
                {...register("email", {
                  required: "Email Required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Email Invalid",
                  },
                })}
                className={`p-2  outline-none rounded-md ${
                  errors.email ? "border-2 border-red-500" : ""
                }`}
              />
            </div>
            <div className="flex flex-col">
              {errors.password && (
                <span role="alert" className=" text-red-600 text-center">
                  {errors.password.message}
                </span>
              )}
              <input
                type="password"
                placeholder="Your Password"
                className={`p-2  outline-none rounded-md ${
                  errors.email ? "border-2 border-red-500" : ""
                }`}
                {...register("password", {
                  required: "Password Required",
                  minLength: {
                    value: 5,
                    message: "min length is 5",
                  },
                })}
              />
            </div>
            <div>
              <button
                type="submit"
                className="bg-indigo-300 py-4 mx-auto block rounded-md text-gray-700 px-12 mt-4 hover:bg-gray-700 hover:text-indigo-300 transition-all duration-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
