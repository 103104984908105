import { UserAction, UserActionKind, userType } from "../../types/user";

const initialState: userType = {
  name: "",
  email: "",
  token: "",
  role: "",
  id: "",
  auth: false,
};
function userReducer(state: userType = initialState, action: UserAction) {
  const { type, payload } = action;
  switch (type) {
    case UserActionKind.LOGIN:
      state.email = payload.email;
      state.name = payload.name;
      state.token = payload.token;
      state.role = "admin";
      state.id = payload.id;
      state.auth = true;
      return {
        ...state,
      };
    case UserActionKind.TOKEN:
      state.token = payload.token;
      return {
        ...state,
      };
    case UserActionKind.LOGOUT:
      state.email = "";
      state.name = "";
      state.token = "";
      state.role = "";
      state.auth = false;
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default userReducer;
