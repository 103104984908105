import React from "react";
import { useSelector } from "react-redux/es/exports";
import { Outlet, Navigate } from "react-router-dom";
import { state } from "../types/initial";

const PublicRoute = ({ children, ...rest }: any) => {
  let { auth } = useSelector((state: state) => state.user);
  return !auth ? <Outlet /> : <Navigate to="/" />;
};

export default PublicRoute;
