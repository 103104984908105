import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { updateUser } from "../../helpers/getters";
import { userType } from "../../types/user";
import Snackbar, { SnackEnum } from "../Snackbar/Snackbar";
import CreateUser from "./CreateUser";

type Props = {
  user: userType;
};
interface IFormInput {
  name: string;
  id: number;
}
const User = ({ user }: Props) => {
  const snackbarRef = React.useRef<any>(null);

  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<SnackEnum>(SnackEnum.success);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data, e) => {
    const formData = new FormData();
    formData.append("name", data.name);
    updateUser(formData, Number(user.id))
      .then((response) => {
        setMessage(response);
        snackbarRef.current.show();
      })
      .catch((error) => console.log(error));
  };
  const showMessage = (data: string) => {
    setMessage(data);
    snackbarRef.current.show();
  };
  return (
    <>
      <Snackbar message={message} type={status} ref={snackbarRef} />
      <div className="h-full w-full bg-gray-400 px-12 py-12 rounded-lg flex flex-col space-y-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            {errors.name && (
              <span role="alert" className=" text-red-600 text-center">
                {errors.name.message}
              </span>
            )}
            <label>Όνομα</label>
            <input
              placeholder={user.name}
              {...register("name", {
                required: "Name is Required",
              })}
              className={`p-2  outline-none rounded-md border-2 ${
                errors.name ? "border-2 border-red-500" : ""
              }`}
            />
          </div>
          <button
            type="submit"
            className="bg-green-300 py-2  mx-auto block rounded-md text-gray-700 px-12 mt-4 hover:bg-gray-700 hover:text-indigo-300 transition-all duration-500"
          >
            Update
          </button>
        </form>
      </div>
    </>
  );
};

export default User;
