import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { useQuill } from "react-quilljs";
import { useForm, SubmitHandler } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { login } from "../helpers/login";
import { createBlog } from "../helpers/getters";
import Snackbar, { SnackEnum } from "../components/Snackbar/Snackbar";
import { useSelector } from "react-redux";
import { state } from "../types/initial";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { userType } from "../types/user";
type Props = {};
interface IFormInput {
  title: string;
  image: File[];
  category: string;
}
const animatedComponents = makeAnimated();
const Create = (props: Props) => {
  const snackbarRef = React.useRef<any>(null);
  const { category, users } = useSelector((state: state) => state);
  const [val, setVal] = React.useState<any>();
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<SnackEnum>(SnackEnum.success);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const { quill, quillRef } = useQuill();

  const onSubmit: SubmitHandler<IFormInput> = (data, e) => {
    const formData = new FormData();
    console.log(data.image[0]);
    formData.append("image", data.image[0]);
    formData.append("creator_id", JSON.stringify(val));
    formData.append("title", data.title);
    formData.append("category_id", data.category);
    if (quill) {
      formData.append("body", quill.root.innerHTML);
    }
    createBlog(formData)
      .then((response) => {
        setMessage(response);
        if (snackbarRef.current) {
          snackbarRef.current.show();
          quill?.deleteText(0, quill.getLength());
          reset();
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setStatus(SnackEnum.error);
        if (snackbarRef.current) {
          snackbarRef.current.show();
        }
      });
  };

  return (
    <>
      <Navbar />
      <Snackbar message={message} type={status} ref={snackbarRef} />
      <div className="mt-32 px-32 mb-32">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 border-2 px-8 py-12 rounded-lg"
        >
          <h3 className="tracking-widest text-xl font-mono">New Article</h3>
          <div className="flex flex-col">
            {errors.title && (
              <span role="alert" className=" text-red-600 text-center">
                {errors.title.message}
              </span>
            )}
            <input
              placeholder="Title"
              {...register("title", {
                required: "Title is Required",
              })}
              className={`p-2  outline-none rounded-md border-2 ${
                errors.title ? "border-2 border-red-500" : ""
              }`}
            />
          </div>
          <div className="flex flex-col">
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder="Συντάκτες"
              isMulti
              onChange={(choice) => setVal(choice)}
              options={users}
              getOptionLabel={(option: userType) => option.name}
              getOptionValue={(option: any) => option.id}
            />
          </div>
          <div className="flex flex-col">
            {errors.category && (
              <span role="alert" className=" text-red-600 text-center">
                {errors.category.message}
              </span>
            )}

            <select
              {...register("category", { required: "Category is Required" })}
              className={`p-2  outline-none rounded-md border-2 ${
                errors.title ? "border-2 border-red-500" : ""
              }`}
              placeholder="Category"
            >
              {category?.map((cat) => {
                return <option value={cat.id}>{cat.title}</option>;
              })}
            </select>
          </div>
          <div className="flex flex-col">
            {errors.image && (
              <span role="alert" className=" text-red-600 text-center">
                {errors.image.message}
              </span>
            )}
            <label>Main Image</label>

            <input
              type="file"
              placeholder="Image"
              {...register("image", {
                required: "Image is Required",
              })}
              className={`p-2  outline-none rounded-md ${
                errors.title ? "border-2 border-red-500" : ""
              }`}
            />
          </div>
          <div ref={quillRef} />
          <button
            type="submit"
            className="bg-indigo-300 py-4 mx-auto block rounded-md text-gray-700 px-12 mt-4 hover:bg-gray-700 hover:text-indigo-300 transition-all duration-500"
          >
            Create
          </button>
        </form>
      </div>
    </>
  );
};

export default Create;
