import { instance } from "../axios/axiosInstance";

import { store } from "../redux/store";
import { ApiKind } from "../types/api";
import { BlogActionKind } from "../types/blog";
import { CategoryActionKind } from "../types/category";
import { UserActionKind } from "../types/user";

interface loginData {
  email: string;
  password: string;
}
export const login = function (data: loginData) {
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.LOGIN,
      data: data,
      method: "POST",
    })
      .then((res) => {
        let name = res.data.user.name;
        let email = res.data.user.email;
        let token = res.data.token;
        let id = res.data.user.id;
        const user = { name, email, token, id };
        store.dispatch({ type: UserActionKind.LOGIN, payload: user });
        resolve(res.data.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const logout = function () {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.LOGOUT,
      method: "POST",
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        store.dispatch({ type: UserActionKind.LOGOUT });
        store.dispatch({ type: CategoryActionKind.LOGOUT });
        store.dispatch({ type: BlogActionKind.LOGOUT });
        resolve(res.data.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
