import DOMPurify from "dompurify";
import { ClientRequest } from "http";
import React from "react";
import { useNavigate } from "react-router-dom";
import { deleteCategory } from "../../helpers/category";
import { formatDate } from "../../helpers/date";
import { ApiKind } from "../../types/api";
import { blogType } from "../../types/blog";
import { categoryType } from "../../types/category";
import Modal from "../modal/Modal";
import Snackbar, { SnackEnum } from "../Snackbar/Snackbar";

type Props = {
  category: categoryType;
};

const Category = ({ category }: Props) => {
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<SnackEnum>(SnackEnum.success);
  const snackRef = React.useRef<any>(null);
  const modalRef = React.useRef<any>(null);
  const deleteBlogFunction = () => {
    deleteCategory(category.id)
      .then((response) => {
        setMessage(response);
        if (snackRef.current) {
          snackRef.current.show();
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        if (snackRef.current) {
          snackRef.current.show();
        }
      });
  };
  return (
    <>
      <Modal
        message="Are you sure you want to delete this article?"
        ref={modalRef}
        action={deleteBlogFunction}
        id={category.id}
      />
      <Snackbar message={message} type={status} ref={snackRef} />
      <div
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8) ),url(${
            ApiKind.IMAGE + category.image.path
          }) `,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-[500px] rounded-xl flex text-center justify-center text-white relative"
      >
        <div className="flex-row px-12  space-y-2  absolute bottom-12">
          <div>
            <h3 className="font-thin tracking-widest text-center w-full">
              {category.title}
            </h3>
          </div>
        </div>
        <div>
          <button
            className="bg-red-500 text-gray-50 px-2 py-1 rounded-md absolute right-0 z-30"
            onClick={() => modalRef.current.show()}
          >
            DELETE
          </button>
        </div>
      </div>
    </>
  );
};

export default Category;
