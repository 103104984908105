import React from "react";
import { motion } from "framer-motion";
import { MdCancel } from "react-icons/md";
import { SubmitHandler, useForm } from "react-hook-form";
import { createCategories } from "../../helpers/category";
import { createUser } from "../../helpers/getters";
type Props = {
  message: string;
  action: (info: string) => void;
};
interface IFormInput {
  name: string;
  email: string;
  password: string;
}
const variants = {
  open: { opacity: 1, x: 0, innerWidth: 0 },
  closed: { opacity: 0, x: "-100%" },
};
const CreateUser = React.forwardRef(({ message, action }: Props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  React.useImperativeHandle(ref, () => ({
    show(): any {
      setIsOpen(true);
    },
  }));
  const onSubmit: SubmitHandler<IFormInput> = (data, e) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    createUser(formData)
      .then((response) => {
        action(response);
        setIsOpen(false);
      })
      .catch((error) => {});
  };
  return (
    <>
      <motion.div
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        initial={false}
        transition={{ type: "linear" }}
        className=" bg-gray-600 bg-opacity-50 z-40 w-screen
      h-screen fixed top-0 left-0 justify-center items-center flex"
      >
        <div className="bg-gray-100 p-12 text-lg text-gray-700 z-50 rounded-lg relative">
          <h4 className="text-xl">Δημιουργία Χρήστη</h4>
          <button
            className="absolute top-2 right-2"
            onClick={() => setIsOpen(!isOpen)}
          >
            <MdCancel size={22} />
          </button>
          <div className="flex justify-end mt-4 space-x-4 ">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-4 border-2 px-8 py-12 rounded-lg"
            >
              <div className="flex flex-col">
                {errors.name && (
                  <span role="alert" className=" text-red-600 text-center">
                    {errors.name.message}
                  </span>
                )}
                <input
                  placeholder="Όνομα"
                  {...register("name", {
                    required: "Name is Required",
                  })}
                  className={`p-2  outline-none rounded-md border-2 ${
                    errors.name ? "border-2 border-red-500" : ""
                  }`}
                />
              </div>

              <div className="flex flex-col">
                {errors.email && (
                  <span role="alert" className=" text-red-600 text-center">
                    {errors.email.message}
                  </span>
                )}
                <input
                  placeholder="Your Email"
                  {...register("email", {
                    required: "Email Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Email Invalid",
                    },
                  })}
                  className={`p-2  outline-none rounded-md ${
                    errors.email ? "border-2 border-red-500" : ""
                  }`}
                />
              </div>
              <div className="flex flex-col">
                {errors.password && (
                  <span role="alert" className=" text-red-600 text-center">
                    {errors.password.message}
                  </span>
                )}
                <input
                  type="password"
                  placeholder="Your Password"
                  className={`p-2  outline-none rounded-md ${
                    errors.email ? "border-2 border-red-500" : ""
                  }`}
                  {...register("password", {
                    required: "Password Required",
                    minLength: {
                      value: 5,
                      message: "min length is 5",
                    },
                  })}
                />
              </div>

              <button
                type="submit"
                className="bg-indigo-300 py-4 mx-auto block rounded-md text-gray-700 px-12 mt-4 hover:bg-gray-700 hover:text-indigo-300 transition-all duration-500"
              >
                Create
              </button>
            </form>
          </div>
        </div>
      </motion.div>
    </>
  );
});

export default CreateUser;
