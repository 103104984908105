import { UserAction, UserActionKind, userType } from "../../types/user";

const initialState: Array<userType> = [];
function usersReducer(state: userType[] = initialState, action: UserAction) {
  const { type, payload } = action;
  switch (type) {
    case UserActionKind.GETALLUSERS:
      return payload;
    case UserActionKind.UPDATEUSER:
      return state.map((item, index) => {
        return payload.id === item.id
          ? {
              ...payload,
            }
          : item;
      });
    case UserActionKind.CREATENEWUSER:
      return [...state, payload];
    case UserActionKind.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default usersReducer;
