export enum ApiKind {
  CREATEBLOG = "blog/store",
  GETBLOGS = "getblog",
  GETUSERS = "user/getAll",
  GETCATEGORIES = "getcategory",
  CREATEUSER = "user/register",
  UPDATEUSER = "user/",
  CREATECATEGORY = "category/store",
  DELETECATEGORY = "category/delete",
  DELETEBLOG = "blog/delete/",
  LOGIN = "auth/login",
  LOGOUT = "/logout",
  UPDATEBLOG = "blog/",
  //IMAGE = "http://127.0.0.1:8000",
  IMAGE = "https://api.itsallpolitics.gr/",
}
