import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { useSelector } from "react-redux/es/exports";
import { logout } from "../../helpers/login";
type Props = {};

const Navbar = (props: Props) => {
  const { user } = useSelector((state: any) => state);
  const handleLogout = () => {
    logout()
      .then((t) => {
        console.log(t);
      })
      .catch((error) => {});
  };
  return (
    <div className="grid grid-cols-4 px-32 sticky top-0 bg-indigo-100 z-10">
      <div className=" py-4">
        <img src="/assets/logo.png" alt="logo" width={132} height={128} />
      </div>
      <div className="col-span-3 flex justify-end gap-8 items-center font-mono">
        <div className="flex flex-row justify-space items-center space-x-4">
          <div className="col-span-3 flex justify-center gap-8 items-center font-mono">
            <NavLink
              className={({ isActive }) =>
                `${
                  isActive ? "underlined" : "hover-underline-animation"
                } text-[#545e6f]`
              }
              to="/"
            >
              ΑΡΘΡΑ
            </NavLink>
            <NavLink
              to="/categories"
              className={({ isActive }) =>
                `${
                  isActive ? "underlined" : "hover-underline-animation"
                } text-[#545e6f]`
              }
            >
              ΚΑΤΗΓΟΡΙΕΣ
            </NavLink>
            <NavLink
              to="/users"
              className={({ isActive }) =>
                `${
                  isActive ? "underlined" : "hover-underline-animation"
                } text-[#545e6f]`
              }
            >
              ΧΡΗΣΤΕΣ
            </NavLink>
            <NavLink
              to="/create"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-200 bg-green-600 p-2 rounded-md"
                    : "text-gray-200 bg-green-500 p-2 rounded-md"
                } transition-all duration-500`
              }
            >
              ΔΗΜΙΟΥΡΓΙΑ
            </NavLink>
            <button
              className="bg-red-500 p-2 rounded-lg text-slate-50"
              onClick={handleLogout}
            >
              Αποσύνδεση
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
