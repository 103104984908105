import { BlogAction, BlogActionKind, blogType } from "../../types/blog";

const initialState: Array<blogType> = [];
function blogReducer(state: blogType[] = initialState, action: BlogAction) {
  const { type, payload } = action;
  switch (type) {
    case BlogActionKind.GETALL:
      return payload;
    case BlogActionKind.LOGOUT:
      return initialState;
    case BlogActionKind.ADD:
      return [payload, ...state];
    case BlogActionKind.DELETE:
      return state.filter((element) => element.id !== action.payload);
    case BlogActionKind.UPDATE:
      return state.map((item, index) => {
        return payload.id === item.id
          ? {
              ...payload,
            }
          : item;
      });
    default:
      return state;
  }
}

export default blogReducer;
