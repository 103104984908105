import { instance } from "../axios/axiosInstance";
import { store } from "../redux/store";
import { ApiKind } from "../types/api";
import { BlogActionKind } from "../types/blog";
import { CategoryActionKind, categoryType } from "../types/category";
import { UserActionKind } from "../types/user";
export const getCategories = function () {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.GETCATEGORIES,
      method: "GET",
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        store.dispatch({
          type: CategoryActionKind.GETALLCATEGORIES,
          payload: res.data,
        });
        resolve(res.data.message);
      })
      .catch((error: string) => {
        store.dispatch({ type: UserActionKind.LOGOUT });
        store.dispatch({ type: BlogActionKind.LOGOUT });
        reject(error);
      });
  });
};
export const createCategories = function (data: any) {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.CREATECATEGORY,
      method: "POST",
      data: data,
      headers: {
        Authorization: "Bearer " + state.user.token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        store.dispatch({
          type: CategoryActionKind.CREATECATEGORY,
          payload: res.data.data,
        });
        resolve(res.data.message);
      })
      .catch((error: string) => {
        reject(error);
      });
  });
};
export const deleteCategory = function (id: number) {
  const state = store.getState();
  return new Promise<string>((resolve, reject) => {
    instance({
      url: ApiKind.DELETECATEGORY + "/" + id,
      method: "DELETE",
      headers: { Authorization: "Bearer " + state.user.token },
    })
      .then((res) => {
        store.dispatch({
          type: CategoryActionKind.DELETECATEGORY,
          payload: id,
        });
        resolve(res.data.message);
      })
      .catch((error) => {
        // store.dispatch({ type: UserActionKind.LOGOUT });
        // store.dispatch({ type: BlogActionKind.LOGOUT });

        reject(error);
      });
  });
};
