import React from "react";
import { motion } from "framer-motion";
type Props = {
  message: string;
  action: (id: number) => void;
  id: number;
};

const variants = {
  open: { opacity: 1, x: 0, innerWidth: 0 },
  closed: { opacity: 0, x: "-100%" },
};
const Modal = React.forwardRef(({ message, action, id }: Props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  React.useImperativeHandle(ref, () => ({
    show(): any {
      setIsOpen(true);
    },
  }));
  return (
    <>
      <motion.div
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        initial={false}
        transition={{ type: "linear" }}
        className=" bg-gray-600 bg-opacity-50 z-40 w-screen
      h-screen fixed top-0 left-0 justify-center items-center flex"
      >
        <div className="bg-gray-100 p-12 text-lg text-gray-700 z-50 rounded-lg">
          <h4>{message}</h4>
          <div className="flex justify-end mt-4 space-x-4">
            <button
              className="bg-red-400 px-4 rounded-lg"
              onClick={() => {
                action(id);
                setIsOpen(false);
              }}
            >
              Yes
            </button>
            <button
              className="bg-gray-400 px-4 rounded-lg"
              onClick={() => setIsOpen(!isOpen)}
            >
              No
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
});

export default Modal;
