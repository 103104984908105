export interface userType {
  role: string;
  id: number | undefined | string;
  name: string;
  email: string;
  token: string;
  auth: boolean;
}

export enum UserActionKind {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  TOKEN = "TOKEN",
  GETALLUSERS = "GETALLUSERS",
  UPDATEUSER = "UPDATEUSER",
  CREATENEWUSER = "CREATENEWUSER",
}
export interface UserAction {
  type: UserActionKind;
  payload?: any;
}
