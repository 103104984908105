import React from "react";
import { useSelector } from "react-redux/es/exports";
import { Outlet, Navigate } from "react-router-dom";
import { state } from "../types/initial";

const PrivateRoute = ({ children, ...rest }: any) => {
  let { auth } = useSelector((state: state) => state.user);
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
