import React from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import Blog from "../components/Blog/Blog";
import Blogs from "../components/Blog/Blogs";
import Navbar from "../components/Navbar/Navbar";
import Pagination from "../components/Pagination/Pagination";
import { blogType } from "../types/blog";
import { state } from "../types/initial";

type Props = {};

const Home = (props: Props) => {
  const { blogs } = useSelector((state: state) => state);
  return (
    <>
      <Navbar />
      <Pagination itemsPerPage={6} items={blogs} />
    </>
  );
};

export default Home;
