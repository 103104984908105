import {
  CategoryAction,
  CategoryActionKind,
  categoryType,
} from "../../types/category";

const initialState: Array<categoryType> = [];
function categoryReducer(
  state: categoryType[] = initialState,
  action: CategoryAction
) {
  const { type, payload } = action;
  switch (type) {
    case CategoryActionKind.GETALLCATEGORIES:
      return payload;
    case CategoryActionKind.CREATECATEGORY:
      return [...state, payload];
    case CategoryActionKind.LOGOUT:
      return initialState;
    case CategoryActionKind.DELETECATEGORY:
      return state.filter((element) => element.id !== action.payload);
    default:
      return state;
  }
}

export default categoryReducer;
