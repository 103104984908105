import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Navbar from "../components/Navbar/Navbar";
import Snackbar, { SnackEnum } from "../components/Snackbar/Snackbar";
import { updateUser } from "../helpers/getters";
import { state } from "../types/initial";
import { RiAddCircleFill } from "react-icons/ri";
import { motion } from "framer-motion";
import CreateUser from "../components/modal/CreateUser";
import User from "../components/modal/User";
type Props = {};

const variantImage2 = {
  hidden: {
    y: "0",
    opacity: 1,
  },
  visible: {
    y: -600,
    opacity: 0,
    transition: { delay: 0.1, type: "linear", y: { duration: 1 } },
  },
  exit: {
    y: "-100vh",
    opacity: 0,
    transition: { delay: 0.1, type: "linear", y: { duration: 1 } },
  },
};
const Users = (props: Props) => {
  const { users } = useSelector((state: state) => state);

  const [clicked, setClicked] = React.useState(false);
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<SnackEnum>(SnackEnum.success);
  const snackbarRef = React.useRef<any>(null);
  const modalRef = React.useRef<any>(null);
  const showMessage = (data: string) => {
    setMessage(data);
    snackbarRef.current.show();
  };
  return (
    <div>
      <Navbar />
      <CreateUser
        message="Are you sure you want to delete this article?"
        ref={modalRef}
        action={showMessage}
      />
      <Snackbar message={message} type={status} ref={snackbarRef} />
      <div className="grid grid-cols-6 mt-12 mx-12 gap-4">
        {users.map((user) => {
          return <User user={user} />;
        })}

        <motion.button className="flex justify-center items-center">
          <RiAddCircleFill
            size={64}
            color="#9544cf"
            onClick={() => modalRef.current.show()}
          />
        </motion.button>
      </div>
    </div>
  );
};

export default Users;
