import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { useQuill } from "react-quilljs";
import { useForm, SubmitHandler } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { login } from "../helpers/login";
import { createBlog } from "../helpers/getters";
import Snackbar, { SnackEnum } from "../components/Snackbar/Snackbar";
import { useSelector } from "react-redux";
import { state } from "../types/initial";
import CreateModal from "../components/modal/CreateModal";
import Category from "../components/Blog/Category";
type Props = {};
interface IFormInput {
  title: string;
  image: File[];
  category: string;
}
const Categories = (props: Props) => {
  const snackbarRef = React.useRef<any>(null);
  const { category } = useSelector((state: state) => state);
  const modalRef = React.useRef<any>(null);
  const [message, setMessage] = React.useState<string>("");
  const [status, setStatus] = React.useState<SnackEnum>(SnackEnum.success);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const { quill, quillRef } = useQuill();

  const onSubmit: SubmitHandler<IFormInput> = (data, e) => {
    const formData = new FormData();
    console.log(data.image[0]);
    formData.append("image", data.image[0]);
    formData.append("title", data.title);
    formData.append("category_id", data.category);
    if (quill) {
      formData.append("body", quill.root.innerHTML);
    }
    createBlog(formData)
      .then((response) => {
        setMessage(response);
        if (snackbarRef.current) {
          snackbarRef.current.show();
          quill?.deleteText(0, quill.getLength());
          reset();
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setStatus(SnackEnum.error);
        if (snackbarRef.current) {
          snackbarRef.current.show();
        }
      });
  };
  const showMessage = (data: string) => {
    setMessage(data);
    snackbarRef.current.show();
  };
  return (
    <>
      <Navbar />
      <Snackbar message={message} type={status} ref={snackbarRef} />
      <CreateModal
        message="Are you sure you want to delete this article?"
        ref={modalRef}
        action={showMessage}
      />
      <div className="w-screen px-32 mt-4 flex justify-end">
        <button
          className="bg-green-400 p-2 rounded-xl text-slate-700"
          onClick={() => modalRef.current.show()}
        >
          Νεα Κατηγορία
        </button>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 px-12 gap-4 mt-4">
        {category.length > 0 &&
          category.map((cat) => {
            return <Category category={cat} />;
          })}
      </div>
    </>
  );
};

export default Categories;
